import React, { ReactNode } from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { SectionText1 } from "StaticPages/Components/Commons/SectionText1";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { ScrollTrigger } from "mui-extensions/src/ScrollTrigger";
import { TransitionImage } from "StaticPages/Components/TransitionImage";
import { FadeAndSlideAnimation } from "StaticPages/Components/Animations/FadeAndSlideAnimation";
import { ViewMoreButton } from "StaticPages/Components/Commons/ViewMoreButton";
import { css } from "@mui/material";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title="WEB APP | VISUALIZ INC" />
        <Layout>
            <div>
                <Main />
            </div>
        </Layout>
    </>;
};

const Main = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return <>
        <main >
            <PageSummary
                subTitle={t("WEB APP")}
                title={t("WEBサイト・アプリケーション開発")}
                description={<>
                    {t("見る、聞く、戯れる、")}<br />
                    {t("五感をくすぐるようなWEBサイトやアプリケーション")}<br />
                    {t("事業収益を向上させるサービスを提供します")}<br />
                </>}
                src={"/images/Service_Page_Header.jpg"}
            />

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("Web制作は次のステージへ")}
                />

                <Box
                    my={{ xs: 2, sm: 3, md: 4 }}
                    maxWidth="1080px"
                    mx="auto">
                    <ScrollTrigger once>
                        {state => <SkewAnimation
                            in={state === "entered"}
                        >
                            <Typography
                                variant="body2"
                                component="h3"
                                sx={{
                                    lineHeight: { xs: 1.8, sm: 2.2 },
                                    letterSpacing: { xs: "0.07em", sm: "0.17em" },
                                    fontSize: {
                                        xs: "0.82rem",
                                        sm: "0.92rem",
                                        md: "1rem",
                                    }
                                }}>
                                {t("フロントエンドを司るVue, React, Node.js")}<br />
                                {t("バックエンドマネジメントはCMS, Node.js, PHP, Python")}<br />
                                {t("含めCMSの次を知る事は、")}<br />
                                {t("勝てるビジネスを提案する事でもある")}
                            </Typography>
                        </SkewAnimation>}
                    </ScrollTrigger>
                </Box>

                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("個人事業だったBuddysを含めWeb制作を生業の一つとして既に20年が経過した。")}
                    </SectionText1>
                    <SectionText1>
                        {t("サイト制作の全てをCMSにしたのはWordPress 2.3辺りからだったろうか。現在の管理数は70社程度だとしても、200サイト近い数は制作しているように思う。")}
                    </SectionText1>
                    <SectionText1>
                        {t("マーケティングプロセス、直打ちのコーディングからテーマ管理、そしてサーバからクラウドへと言った特にこの数年の間、Webを取り巻く環境、制作サイドに於ける環境の変化は目を見張るものがある。")}
                    </SectionText1>
                    <SectionText1>
                        {t("フロントエンド開発、アジャイルといった概念は既にWebサイト制作はWebアプリケーションの開発へと変貌しつつある事を示唆している。")}
                    </SectionText1>
                    <SectionText1>
                        {t("大規模なシステムでさえ、マイクロシステムの組み合わせで動作し、運用に関しても最大値から逆算したシステム構築ではなくクラウドによる構築で飛躍的に自由度が高まっている。そしてこれらの仕組みを利用した我々のWebアプリケーションはサービスとして様々な企業の元、稼働している。")}
                    </SectionText1>
                    <SectionText1>
                        {t("それは単純なWeb販売システムから顧客管理、VRを利用した広告管理システム、AIアノテーションデータ作成システムなど多岐に渡る。")}
                    </SectionText1>
                    <SectionText1>
                        {t("やがてWebを中心とした技術には放送やZOOMに代表されるPtoPに必要なWebRTCなどが必須となってくるだろう。")}
                    </SectionText1>
                    <SectionText1>
                        {t("必要なのはこれらの技術を網羅した開発・運用チームとオーガナイザーの存在だ。")}
                    </SectionText1>
                    <SectionText1>
                        {t("コロナ禍で揺れる社会の中、私たちはWeb開発を通じ、クライアントの目的に寄り添い、アジャイルに振る舞い、そして目標達成までの道筋を照らし続ける。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
                sx={{
                    background: theme.palette.background.default,
                }}
            >
                <SectionTitle2
                    title={t("制作事例")}
                />

                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <ServiceCollectionItem
                        title={t("サテライトトレイン")}
                        src="/images/st-main.jpg"
                        detail={
                            <Box>
                                <Typography> {t("Fronted：Vue.js A-Frame")}</Typography>
                                <Typography>{t("Contents：360VRmovie")}</Typography>
                                <Typography>{t("Server：Microsoft AZURE")}</Typography>
                                <Typography>{t("Start：2020")}</Typography>
                                <Box>
                                    <Typography>{t("広告媒体としての機能を併せ持つVR・Webサイト")}</Typography>
                                    <Typography>{t("キャラクターアニメーションとWebが広告連携を行う全く新しい")}</Typography>
                                    <Typography>{t("コンセプトの元開発されたサービス。")}</Typography>
                                    <Typography>{t("小田急エージェンシー様との共同開発。")}</Typography>
                                </Box>
                            </Box>
                        }
                    />
                    <ServiceCollectionItem
                        title={t("Florist Mon ネットショップ")}
                        src="/images/fm-main.jpg"
                        detail={
                            <Box>
                                <Typography>{t("Fronted：WordPress")}</Typography>
                                <Typography>{t("Contents：カートシステム・POSレジ連動システム")}</Typography>
                                <Typography>{t("Server：レンタルサーバ")}</Typography>
                                <Typography>{t("Start：2020")}</Typography>
                                <Box>
                                    <Typography>{t("CMSで動作するカートシステムに様々な改造を施し機能を追加した。")}</Typography>
                                    <Typography>{t("具体的にはPOSレジとの連動、顧客管理、配達管理など。")}</Typography>
                                    <Typography>{t("株式会社Buddys様との連携で作り、通常のレンタルサーバで動作する")}</Typography>
                                    <Typography>{t("軽快なシステムでもある。")}</Typography>
                                </Box>
                                <a href="https://www.f-mon.com/" target="blank">https://www.f-mon.com/</a>
                            </Box>
                        }
                    />

                    <ServiceCollectionItem
                        title={t("福山ローズファイターズ オフィシャルサイト")}
                        src="/images/rf-main.jpg"
                        detail={
                            <Box>
                                <Typography> {t("Fronted：WordPress")}</Typography>
                                <Typography>{t("Contents：カートシステム・年間サポーター管理システム")}</Typography>
                                <Typography>{t("Server：レンタルサーバ")}</Typography>
                                <Typography>{t("Start：2018")}</Typography>
                                <Box>
                                    <Typography>{t("我々の地元でもある福山市で活動しているセミプロの野球チーム。")}</Typography>
                                    <Typography>{t("広く市民から年間サポーターを募り、権利購入と顧客管理をサイト内で")}</Typography>
                                    <Typography>{t("同時に行うシステムを企画・設計・開発までワンストップで行った。")}</Typography>
                                </Box>
                                <a href="https://f-rosefighters.jp/" target="blank">https://f-rosefighters.jp/</a>
                            </Box>
                        }
                    />

                    <ServiceCollectionItem
                        title={t("CAFE SUPREMO | コーヒー豆通販サイト")}
                        src="/images/cs-main.jpg"
                        detail={
                            <Box>
                                <Typography>{t("Fronted：WordPress")}</Typography>
                                <Typography>{t("Contents：カートシステム・顧客・配送管理システム")}</Typography>
                                <Typography>{t("Server：レンタルサーバ")}</Typography>
                                <Typography>{t("Start：2017")}</Typography>
                                <Box>
                                    <Typography>  {t("山間地域で30年以上続いている老舗珈琲店。")}</Typography>
                                    <Typography>  {t("こだわりの豆を全国にお届けしたいとの思いを実現するための機能と")}</Typography>
                                    <Typography>  {t("「想い」を文字にするそのお手伝いを行った。")}</Typography>

                                </Box>
                                <a href="https://www.supremocoffee.jp/" target="blank">https://www.supremocoffee.jp/</a>
                            </Box>
                        }
                    />

                    <ServiceCollectionItem
                        title={t("山本製作所 | 業務用洗濯機製造メーカー")}
                        src="/images/ys-main.jpg"
                        detail={
                            <Box>
                                <Typography>{t("Fronted：WordPress")}</Typography>
                                <Typography>{t("Contents：ファイル管理システム・検索システム")}</Typography>
                                <Typography>{t("Server：レンタルサーバ")}</Typography>
                                <Typography>{t("Start：2017")}</Typography>
                                <Box>
                                    <Typography>  {t("過去に製造・販売した数百以上存在する全ての機器の操作マニュアル")}</Typography>
                                    <Typography>  {t("エラーメッセージ、カタログなどを管理し、顧客が素早い検索を可能にするシステムを構築。")}</Typography>
                                    <Typography>  {t("顧客満足度の向上に役立てていただいている。")}</Typography>

                                </Box>
                                <a href="https://www.onomichi-yamamoto.co.jp/" target="blank">https://www.onomichi-yamamoto.co.jp/</a>
                            </Box>
                        }
                    />

                    <ServiceCollectionItem
                        title={t("神楽坂 翔山亭")}
                        src="/images/ks-main.jpg"
                        detail={
                            <Box>
                                <Typography>{t("Fronted：WordPress")}</Typography>
                                <Typography>{t("Contents：News管理")}</Typography>
                                <Typography>{t("Server：レンタルサーバ")}</Typography>
                                <Typography>{t("Start：2018")}</Typography>
                                <Box>
                                    <Typography>{t("複数のNews機能を組み合わせ自由度の高いNews配信を可能にした。")}</Typography>
                                    <Typography>{t("またSNSとの連動、アナリティクス分析を行う事でマーケティングデータを提供している。")}</Typography>
                                </Box>
                                <a href="https://www.shozantei.com/" target="blank">https://www.shozantei.com/</a>
                            </Box>
                        }
                    />

                    <ServiceCollectionItem
                        title={t("BOATRACEチケット販売所")}
                        src="/images/bts-main.jpg"
                        detail={
                            <Box>
                                <Typography> {t("Fronted：WordPress")}</Typography>
                                <Typography>{t("Contents：News管理・VRコンテンツ")}</Typography>
                                <Typography>{t("Server：レンタルサーバ")}</Typography>
                                <Typography>{t("Start：2018")}</Typography>
                                <Box>
                                    <Typography>{t("複数のNews機能を組み合わせ自由度の高いNews配信を可能にした。")}</Typography>
                                </Box>
                                <a href="https://bts-ibara.com/" target="blank">https://bts-ibara.com/</a>
                            </Box>
                        }
                    />

                    <ServiceCollectionItem
                        title={t("BIDnaviマネージャ")}
                        src="/images/bn-main.jpg"
                        detail={
                            <Box>
                                <Typography> {t("Fronted：Vue.js")}</Typography>
                                <Typography>      {t("Contents：News管理・VRコンテンツ")}</Typography>
                                <Typography>      {t("Server：Microsoft Azure")}</Typography>
                                <Typography>     {t("Start：2021")}</Typography>
                                <Box>
                                    <Typography>  {t("AIの学習用データセット登録とクローリング結果の管理、それらの正誤判定を行う。")}</Typography>
                                </Box>
                            </Box>
                        }
                    />
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
                maxWidth="900px"
                mx="auto"
            >
                <SectionTitle2
                    title={t("これからも今までも")}
                />

                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("これまでのWeb制作方法が根本から変わりつつある昨今、新しいのは制作手法だけではない。")}
                    </SectionText1>
                    <SectionText1>
                        {t("SNS広告効果の向上に役立つであろうOGAや、その特性からユーザビリティを根本から変える力を持つPWAなどもそうだ。")}
                    </SectionText1>
                    <SectionText1>
                        {t("動画コンテンツの埋め込みなどは当たり前。動的なVRさえ出て来る可能性もある。")}
                    </SectionText1>
                    <SectionText1>
                        {t("現在作成中のAIコンテンツもそうだ。どのようなアイデアであれそれがデジタルであれば夢物語なんて事はなくなって来ている。")}
                    </SectionText1>
                    <SectionText1>
                        {t("それでも私たちが実現させる事は「担当者」の方や「オーナー」が心に持つ「強い思い」から導かれたものだ。")}
                    </SectionText1>
                    <SectionText1>
                        {t("それはデジタル・アナログの境なく昔から言われている、とても大切なビジョナリーでもある。")}
                    </SectionText1>
                    <SectionText1>
                        {t("思いを実現する技術やツールはこれからも驚くようなものが出て来るに違いない。")}
                    </SectionText1>
                    <SectionText1>
                        {t("とは言えビジネスには変えてはいけない部分がある事も事実だ。")}
                    </SectionText1>
                    <SectionText1>
                        {t("これからも今までも私たちには「正しいこと」をスタンスにする義務がある事を忘れてはならない。")}
                    </SectionText1>
                    <SectionText1>
                        {t("そこに「人」が介在する限り。")}
                    </SectionText1>
                </Box>
            </Box>
        </main>
    </>;
};

const ServiceCollectionItem = (
    {
        title,
        src,
        detail,
    }: {
        src: string,
        title: string,
        detail: ReactNode,
    }
) => {
    const theme = useTheme();
    return (
        <ScrollTrigger once scrollStartOffset={"10%"}>
            {state =>
                <Box
                    my={4}
                    sx={{
                        width: "100%"
                    }}
                    px={{
                        xs: 1,
                        sm: 2,
                        md: 3
                    }}
                    py={{
                        xs: 2,
                        sm: 0
                    }}
                    bgcolor={theme.palette.background.default}
                    textAlign="center"
                >
                    <Grid container
                        sx={{
                            width: "100%",
                            maxWidth: "1080px",
                            margin: "0 auto"
                        }}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Box
                                px={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}
                                sx={{
                                    width: "100%",
                                    height: "100%"
                                }}
                            >
                                <FadeAndSlideAnimation
                                    in={state === "entered"}
                                    transform={{
                                        rotate: "0deg",
                                        translate: {
                                            x: "0px",
                                            y: "0px",
                                        },
                                        scale: 0.92,
                                    }}
                                    css={css`
                                        height: 100%;
                                    `}
                                >
                                    <TransitionImage
                                        src={src}
                                        alt={title}
                                        css={css`
                                            height: 100%;
                                        `}
                                    />
                                </FadeAndSlideAnimation>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Box
                                p={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}
                                sx={{
                                    height: "100%"
                                }}
                                textAlign="left"
                                display="flex"
                                flexDirection="column"
                            >
                                <Box mt="auto">
                                    <SkewAnimation
                                        in={state === "entered"}
                                        bgcolor={theme.palette.background.default}
                                    >
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            sx={{
                                            }}
                                        >{title}</Typography>
                                    </SkewAnimation>
                                </Box>
                                <Box
                                    mt={{
                                        xs: 0.5,
                                        sm: 1,
                                    }}
                                >
                                    <SkewAnimation
                                        in={state === "entered"}
                                        bgcolor={theme.palette.background.default}
                                    >
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >{detail}</Typography>
                                    </SkewAnimation>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            }
        </ScrollTrigger>
    );
};